@font-face {
	font-family: 'Alef';
	font-style: normal;
	font-weight: 400;
	src: url('../assets/fonts/Alef-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Alef';
	font-style: normal;
	font-weight: 700;
	src: url('../assets/fonts/Alef-Bold.ttf') format('truetype');
}
