@import './fonts';
@import './colors';

* {
	font-family: 'Alef', sans-serif;
	box-sizing: border-box;
	color: $white;
}
*::-webkit-scrollbar {
	display: none;
}
body {
	margin: 0;
	background-color: $black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
button {
	border: none;
	background: none;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
iframe {
	max-width: 491px;
	height: 100%;
}
.swiper-horizontal {
	width: 1002px;
}
.swiper-pagination {
	@media screen and (min-width: 971px) {
		display: none;
	}
}
.swiper-pagination-bullet {
	background: $black !important;
	opacity: 1 !important;
	border: 1px solid $white;
}
.swiper-pagination-bullet-active {
	background: $white !important;
	opacity: 1 !important;
	border: 1px solid $black;
}
.react-loading-skeleton {
	--base-color: #2e3436 !important;
	--highlight-color: #555753 !important;
}

div[data-rmiz-wrap='visible'] {
	&:hover {
		> div > div {
			display: flex;
		}
	}
}

div[data-rmiz-overlay='true'] {
	background-color: rgba(0, 0, 0, 0.95) !important;
}

div[data-rmiz-modal-overlay='hidden'] {
	background-color: rgba(0, 0, 0, 0.95) !important;
}

div[data-rmiz-modal-overlay='visible'] {
	background-color: rgba(0, 0, 0, 0.95) !important;
}

img[data-rmiz-modal-img] {
	width: auto !important;
	height: auto !important;
	max-width: 100vw !important;
	max-height: 100vh !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
